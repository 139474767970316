import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseNavlinkComponent } from '../base-navlink.component';
import { NavCard } from '../../models/navigation.models';

@Component({
	selector: 'uc-nav-card-portrait',
	templateUrl: './nav-card-portrait.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavCardPortraitComponent extends BaseNavlinkComponent {
	@Input() data!: NavCard;
}
